// @flow
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Back from '../components/Back';

const Agentur = (props) => (
  <Layout className={props.transitionStatus}>
    <SEO title="Die Agentur" theme="dark" />
    <article>
			<h1>Die Agentur</h1>
			<section className="separator separator--bottom separator--section">
				<p className="type--justify" lang="de">
					<strong>AlbrechtMink</strong> bietet seit 2010 kreative Lösungen in den Bereichen Markenkommunikation und Zeitschriftenentwicklung. Die Agentur mit Sitz in München wird geführt von Nikolaus Albrecht und Werner Mink, die über 20 Jahre Erfahrung im Bereich Kommunikation gesammelt haben. Nikolaus Albrecht u.a. als Chefredakteur von „Vanity Fair“, „Glamour“ und „Freundin“. Werner Mink u.a. als Creative Director für „Vanity Fair“, „Glamour“, „#Ich“ und „Cover“.
				</p>
			</section>
			<p className="type--justify" lang="en">
				<strong>AlbrechtMink</strong> has been offering creative solutions in the fields of branding and magazine development since 2010. The Munich based agency is headed by Nikolaus Albrecht and Werner Mink, who have been gathering experience in communication for over 20 years. Nikolaus Albrecht as the Editor-in-Chief of “Vanity Fair“, “Glamour“ and “Freundin“. Werner Mink as Creative Director of “Vanity Fair“, “Glamour“, “#Ich“ and “Cover“.
			</p>
		</article>
		<Back />
  </Layout>
);

export default Agentur;
